html,
body,
#root, #root>div {
  height: 100%;
}

html,body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  font-family: 'Montserrat', sans-serif !important;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden; /* Prevent scrolling */
}

img[src=""],
img:not([src]){
  opacity: 0;
}

img[src="*"]{
  opacity: 1;
}

/* WELCOME ANIMATION */

.word {
  font-size:1.3em;
  font-family: 'Montserrat', sans-serif;
  font-weight:400;
  opacity:0;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  white-space:nowrap;
}

.w1 {
  animation: w1anim 5.5s normal;
  
}

.w2 {
  animation: w2anim 5.5s normal;
}

@keyframes w1anim {
  0%{
    opacity: 0;
  }
  25%{
    opacity: 1;
  }
  50% {
    opacity:0;
  }
}

@keyframes w2anim {
  50%{
    opacity: 0;
  }
  75%{
    opacity: 1;
  }
  100% {
    opacity:0;
  }
}

