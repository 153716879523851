.imageContainer {
  transition: 500ms linear;
}

.image {
  width: 100%;
  animation-duration: calc(1ms * var(--timing, 100));
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  object-fit: cover;
  width: 100px;
  height: 100px;
  animation-play-state: paused;
}

.imageContainer {
  opacity: 0;
}

.image {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
/* COMMENT THESE OUT FOR HOW IT WORKS */

.playing .image {
  animation-play-state: running;
}

.clear {
  animation-name: none !important;
}

.animation1 {
  animation-name: ken-burns-bottom-right;
}
.animation2 {
  animation-name: ken-burns-top-right;
}
.animation3 {
  animation-name: ken-burns-top-left;
}
.animation4 {
  animation-name: ken-burns-bottom-left;
}
.animation5 {
  animation-name: ken-burns-middle-left;
}
.animation6 {
  animation-name: ken-burns-middle-right;
}
.animation7 {
  animation-name: ken-burns-top-middle;
}
.animation8 {
  animation-name: ken-burns-bottom-middle;
}
.animation9 {
  animation-name: ken-burns-center;
}
.animation10 {
  animation-name: ken-burns-bottom-right-reverse;
}
.animation11 {
  animation-name: ken-burns-top-right-reverse;
}
.animation12 {
  animation-name: ken-burns-top-left-reverse;
}
.animation13 {
  animation-name: ken-burns-bottom-left-reverse;
}
.animation14 {
  animation-name: ken-burns-middle-left-reverse;
}
.animation15 {
  animation-name: ken-burns-middle-right-reverse;
}
.animation16 {
  animation-name: ken-burns-top-middle-reverse;
}
.animation17 {
  animation-name: ken-burns-bottom-middle-reverse;
}
.animation18 {
  animation-name: ken-burns-center-reverse;
}

.animationFadeOut {
  opacity: 0;
}

.animationFadeIn {
  opacity: 1;
}

@keyframes fadein {
  from {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.5;
  }
}

@keyframes ken-burns-bottom-right {
  to {
    transform: scale3d(1.3, 1.3, 1.3) translate3d(-10%, -7%, 0);
  }
}
@keyframes ken-burns-top-right {
  to {
    transform: scale3d(1.3, 1.3, 1.3) translate3d(-10%, 6%, 0);
  }
}
@keyframes ken-burns-top-left {
  to {
    transform: scale3d(1.3, 1.3, 1.3) translate3d(4%, 6%, 0);
  }
}
@keyframes ken-burns-bottom-left {
  to {
    transform: scale3d(1.3, 1.3, 1.3) translate3d(4%, -7%, 0);
  }
}
@keyframes ken-burns-middle-left {
  to {
    transform: scale3d(1.3, 1.3, 1.3) translate3d(4%, 0, 0);
  }
}
@keyframes ken-burns-middle-right {
  to {
    transform: scale3d(1.3, 1.3, 1.3) translate3d(-4%, 0, 0);
  }
}
@keyframes ken-burns-top-middle {
  to {
    transform: scale3d(1.3, 1.3, 1.3) translate3d(0, 4%, 0);
  }
}
@keyframes ken-burns-bottom-middle {
  to {
    transform: scale3d(1.3, 1.3, 1.3) translate3d(0, -4%, 0);
  }
}
@keyframes ken-burns-center {
  to {
    transform: scale3d(1.3, 1.3, 1.3);
  }
}

@keyframes ken-burns-bottom-right-reverse {
  from {
    transform: scale3d(1.3, 1.3, 1.3) translate3d(-10%, -7%, 0);
  }
}
@keyframes ken-burns-top-right-reverse {
  from {
    transform: scale3d(1.3, 1.3, 1.3) translate3d(-10%, 6%, 0);
  }
}
@keyframes ken-burns-top-left-reverse {
  from {
    transform: scale3d(1.3, 1.3, 1.3) translate3d(10%, 6%, 0);
  }
}
@keyframes ken-burns-bottom-left-reverse {
  from {
    transform: scale3d(1.3, 1.3, 1.3) translate3d(10%, -7%, 0);
  }
}
@keyframes ken-burns-middle-left-reverse {
  from {
    transform: scale3d(1.3, 1.3, 1.3) translate3d(10%, 0, 0);
  }
}
@keyframes ken-burns-middle-right-reverse {
  from {
    transform: scale3d(1.3, 1.3, 1.3) translate3d(-10%, 0, 0);
  }
}
@keyframes ken-burns-top-middle-reverse {
  from {
    transform: scale3d(1.3, 1.3, 1.3) translate3d(0, 10%, 0);
  }
}
@keyframes ken-burns-bottom-middle-reverse {
  from {
    transform: scale3d(1.3, 1.3, 1.3) translate3d(0, -10%, 0);
  }
}
@keyframes ken-burns-center-reverse {
  from {
    transform: scale3d(1.3, 1.3, 1.3);
  }
}
